<template>
  <div
    :class="['input-drop-zone rounded', hover ? 'hover' : '']"
    @drop.prevent="dropFile"
    @dragenter="dragEnter"
    @dragover.prevent="dragEnter"
    @dragleave="dragLeave"
  >
    <slot name="title"/>
  <!-- add error message to explain you can't add more files / disabled click & add only file to add up to 15 -->
    <div :class="['input-click-zone', hover ? 'hover' : '']"
      :style="this.files.length ? ' justify-content: space-evenly' : ' justify-content: center'"
    >
      <div class="input-drop-zone__handle-area d-flex flex-column">
        <div class="input-drop-zone__handle-area-wrapper">
          <!-- make icon shrink based on number of files selected so it frees up space -->
          <v-icon v-if="files.length <= 6" :size="100" color="rgba(0,0,0, .2)">
            {{ `mdi-${typeDropzone}` }}
          </v-icon>
        </div>
        <span style="font-size: 1.2rem; font-weight: 600; color: rgba(0,0,0, .8)">
          {{ $t('UPLOAD.DRAGNDROP') }} {{ $t(typeUploadFile) }}
        </span> 
        <br>
        <span style="font-size: 1.2rem; color: rgba(0,0,0, .2)">
          {{ $t("UPLOAD.OR") }}
        </span>
        <br>
        <span v-if="isXImpactModele" style="font-size: 1rem; font-weight: 600; color: rgba(0,0,0, .8)">
          {{ $t("UPLOAD.IMPACT_BUTTONS_TITLE") }}
        </span>
        <br>
        <div
          class="input-drop-zone__active"
          style="font-size: 1.2rem; color: rgba(0,0,0, .3)"
        >
          <v-btn
            color="accent"
            class="input-drop-zone__button-upload d-flex"
            :class="{'mr-3': isXImpactModele}"
            @click="uploadFile"
          >
            {{ isXImpactModele ? $t('UPLOAD.IMPACT_BUTTONS.FROM_FILE') : $t('UPLOAD.CLICK')}}
          </v-btn>
          <slot name="second-button"/>
        </div>
      </div>
      <div v-if="files && validFile" style="display: flex; min-width: 100%; flex-direction: row; justify-content: center; align-items: center">
        <div class="files-container">
          <template v-for="(file, i) in files">
            <div class="file-item" :key="`file-${i}`" @click.stop="removeFile(i)">
              <img :src="extensions[file.name.split('.')[1]]" width="30" height="30" style="margin-right: 10px">
              <span class="file-title">
                {{file.name}}
              </span>
              <v-icon color="error">
                mdi-close
              </v-icon>
            </div>
          </template>
        </div>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <div key="1" v-if="files.length > 0">
        <slot name="button"/>
      </div>
      <div key="2" style="min-height: 45px; margin-top: 20px" v-else/>
    </transition>
    <input
      type="file"
      ref="files"
      name="files"
      multiple
      class="input-drop-zone-input"
      @change="loadFile"
    >
    <slot />
  </div>
</template>

<script>

import PDF from '../../public/img/icons/PDF.png';
import JPG from '../../public/img/icons/JPG.png';
import PNG from '../../public/img/icons/PNG.png';
import TXT from '../../public/img/icons/TXT.png';

export default {
  name: 'InputDropzone',
  data: () => ({
    hover: false,
    files: [],
    extensions: {
      pdf: PDF,
      png: PNG,
      jpg: JPG,
      jpeg: JPG,
      txt: TXT,
    },
    acceptedFileTypeOriginal: ["application/pdf", "image/png", "image/jpeg", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "text/plain"],
    fileTypeForMatching: ["application/pdf"],
  }),
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: String,
      default: '1',
    },
    acceptFileType: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '150px',
    },
    mode: {
      type: String,
      default: 'cv'
    },
    typeDropzone: {
      type: String,
      default: 'download',
    },
    validFile: {
      type: Boolean,
      default: false,
    },
    module: {
      type: String,
      default: '',
    }
  },
  computed: {
    typeUploadFile() {
      if (this.module === 'xranking-matching') return this.mode === 'offer' ? 'UPLOAD.TO_LOAD_JOB' : 'UPLOAD.TO_LOAD_CV'

      return this.mode === 'job' ? 'UPLOAD.TO_LOAD_JOB': 'UPLOAD.TO_LOAD_CV'
    },

    isXImpactModele() {
      return this.module === 'ximpact' ? true : false
    },
  },
  methods: {
    checkFormat(fileType) {
      let chosenTypeToCheck = this.acceptFileType ? this.acceptFileType : this.acceptedFileTypeOriginal;
     
      if (chosenTypeToCheck.includes(fileType)) {
        return true;
      }
      else {
        this.$store.dispatch('messenger/add', 
          { type: 'error', code: 'DEFAULT_MESSAGE', message: this.$t('MESSAGE.CREATIVE.UNSUPPORTED_FORMAT'), time: 5000 })
        return false; 
      }
    },

    dropFile (event) {
      this.hover = false;
      const newFiles = event.dataTransfer.files || [];
            
      if (newFiles.length) {
        Object.values(newFiles).forEach(newFile => this.checkForDrop(newFile) ? null : this.files.push(newFile))
        this.$emit('input', this.files);
        this.$emit('change', this.files);
        return;
      }
      return;
    },

    checkForDrop (newFile) {
      if (this.files.some(file => file.name === newFile.name) || this.files.length >= this.maxLength || !this.checkFormat(newFile.type) ) {
        return true
      }
      return false
    },

    uploadFile () {
      if (this.files.length < this.maxLength) 
        this.$refs.files.click()
      return null;
    }, 

    dragEnter (event) {
      this.hover = true;
    },

    dragLeave (event) {
      this.hover = false;
    },

    removeFile (position) {
      if (this.files.length > 1) {
        this.files.splice(position, 1);
      }
      else {
        this.files = [];
      }
      this.$emit('input', this.files)
      this.$emit('change', this.files)
    },

    loadFile () {
      const newFiles = this.$refs.files;
      if (newFiles.files.length && this.checkFormat(newFiles.files[0].type)) {
        Object.values(newFiles.files).forEach(newFile => 
          this.files.push(newFile)
        )
      }
      
      if (this.files) {
        this.$emit('input', this.files);
        this.$emit('change', this.files);
        return;
      }
      return;
    }
  }
}
</script>

<style lang="scss">
  .fade-enter-active,
  .fade-leave-active {
      transition: opacity .3s
  }

  .fade-enter,
  .fade-leave-to {
      opacity: 0
  }

  .input-drop-zone-input {
    display: none;
  }
  /* .input-drop-zone:hover {
    background-color: rgba(0,0,0,.05);
  } */
  .input-drop-zone.hover {
    background-color: rgba(0,0,0, .1);
  }
  /* .input-drop-zone.hover > * {
    opacity: .5;
    pointer-events: none; */
  /* } */
  .input-drop-zone {
    user-select: none;
    /* cursor: pointer; */
    /* border: solid 2px #ddd; */
    /* background: #fff; */
    height: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    transition: all .3s cubic-bezier(.25,.8,.25,1)
  }

  .input-click-zone {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 80%;
    padding: 50px 60px;
    border: 3px dashed #ddd;
    border-radius: 15px;
    background: #fff;
    box-shadow: 0 0px 0px rgba(0,0,0,0), 0 0px 0px rgba(0,0,0,0);
    transition: all .3s cubic-bezier(.25,.8,.25,1);
  }

  .input-click-zone:hover {
    background: #f7f7f7;
    border-color: #ccc;
    cursor: pointer;
    /* box-shadow: 0 8px 16px rgba(0,0,0,0.1), 0 3px 3px rgba(0,0,0,0.15); */
  }

  .input-click-zone.hover {
    border-color: #eec73c;
    /* box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23); */
  }

  .input-drop-zone__handle-area {
    margin: 0 50px;
    width: 100%;
  }

  .input-drop-zone__active {
    display: flex;
    justify-content: center;

    @media (max-width: 1280px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .files-container {
    display: flex; 
    flex-direction: row;
    flex-wrap: wrap;

    /* width: 100%; */
    /* min-height: 260px; */
    max-width: 615px;
    margin-top: 20px;

    align-items: center;
    justify-content: flex-start;
  }

  .file-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    border: 2px solid rgba(0,0,0, 0.1);
    border-radius: 5px;
    margin: 2px;
    width: 200px;
  }

  .file-item:hover {
    border-color: #f44336;
  }

  .file-item:active {
    border-color: #f44336;
    background-color: #eee;
  }

  .file-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 8px 0 8px;
    width: 200px
  }
</style>
